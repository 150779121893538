<template>
  <div class="form-utm-builder-container grey lighten-3">
    <v-card class="form-utm-builder-wrapper pa-5" flat>
      <div class="font-weight-bold">Campaign URL Builder</div>

      <v-divider class="my-3"></v-divider>

      <form-utm-builder is-input-url></form-utm-builder>
    </v-card>
  </div>
</template>

<script>
import FormUtmBuilder from "@/core/components/modals/ModalUTMBuilder/components/FormUtmBuilder.vue";
export default {
  components: {
    FormUtmBuilder
  }
};
</script>

<style scoped>
.form-utm-builder-container {
  height: 100vh;
}
.form-utm-builder-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  overflow-y: scroll;
  max-height: 100%;
}
</style>
